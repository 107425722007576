<template>
  <div>
    <b-button v-b-toggle.sidebar-left variant="primary" size="sm"
      >View Past Reports <i class="fa fa-file"></i
    ></b-button>
    <b-sidebar
      id="sidebar-left"
      width="60vw"
      backdrop
      title="Past Investor Reports"
      shadow
    >
      <div class="px-3 py-2">
        <table class="table">
          <thead>
            <tr>
              <th>Created By</th>
              <th>Date</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">John Doe</td>
              <td>2020-02-10</td>
              <td class="d-flex">
                <button class="badge ml-1 badge-primary">
                  <i class="fa fa-file"></i>
                </button>
                <button class="badge ml-1 badge-success">
                  <i class="fa fa-paper-plane"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "InvestorReportRecords",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
