<template>
  <div>
    <b-button
      v-b-toggle.sidebar-right
      variant="dark"
      size="sm"
      @click="fetchInvestors()"
      >View Investors List <i class="fa fa-users"></i
    ></b-button>
    <b-sidebar
      id="sidebar-right"
      title="Investors List"
      width="70vw"
      backdrop
      right
      shadow
    >
      <div class="px-3 py-2">
        <div class="row" v-if="loading">
          <div
            class="col-12 col-md-6 col-lg-3 py-2"
            v-for="item in 4"
            :key="item"
          >
            <div class="card card-custom bg-white border-white border-0 shadow">
              <div
                class="card-custom-img"
                style="
                  background-image: url(http://res.cloudinary.com/d3/image/upload/c_scale,q_auto:good,w_1110/trianglify-v1-cs85g_cc5d2i.jpg);
                "
              ></div>
              <div class="card-body p-0" style="overflow-y: auto">
                <b-card>
                  <b-skeleton-img class="mb-2"></b-skeleton-img>
                  <p class="mt-2"></p>
                  <b-skeleton
                    v-for="index in 3"
                    :key="index"
                    animation="throb"
                    :width="`${Math.ceil(Math.random() * (100 - 70) + 70)}%`"
                  ></b-skeleton>
                  <div class="d-flex justify-content-center">
                    <b-skeleton type="button" class="mt-3 mx-1"></b-skeleton>
                    <b-skeleton type="button" class="mt-3 mx-1"></b-skeleton>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12 text-right mb-1">
            <button
              class="btn btn-outline-success btn-sm"
              type="button"
              data-toggle="modal"
              data-target="#addInvestorModal"
            >
              Add New Investor <i class="fa fa-user-plus"></i>
            </button>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            v-for="(item, index) in investors"
            :key="index"
          >
            <div class="card">
              <img :src="item.image" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">
                  {{ item.firstname }} {{ item.lastname }}
                </h5>
                <p class="card-text">{{ item.email }}</p>
                <p class="card-text">
                  <button
                    class="badge badge-success"
                    v-if="item.status == 'active'"
                  >
                    Active
                  </button>
                  <button class="badge badge-dark" v-else>Disabled</button>
                </p>
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-warning btn-sm mx-1"
                    @click.prevent="startEditInvestorModal(item)"
                  >
                    <i class="fa fa-pen"></i>
                  </button>
                  <!-- <button class="btn btn-danger btn-sm mx-1" @click.prevent="startDeleteInvestorModal(item)">
                                    <i class="fa fa-trash"></i>
                                </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>

    <!-- Add Modal -->
    <div
      class="modal fade"
      id="addInvestorModal"
      role="dialog"
      aria-labelledby="addInvestorModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>Add New Investor</p>
                </div>

                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          id="c-name"
                          v-model="newInvestor.firstname"
                          class="form-control"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          id="c-email"
                          v-model="newInvestor.lastname"
                          class="form-control"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          id="c-occupation"
                          v-model="newInvestor.email"
                          class="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn"
              id="btn_hide_add_new_user_modal"
              data-dismiss="modal"
            >
              <i class="flaticon-delete-1"></i> Dismiss
            </button>

            <button
              id="btn-add"
              @click.prevent="addNewInvestor()"
              class="btn btn-success"
              :disabled="loadingNewInvestor"
            >
              <b-spinner small v-if="loadingNewInvestor"></b-spinner>
              <span v-else>Add <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editInvestorModal"
      role="dialog"
      aria-labelledby="editInvestorModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>Edit Investor</p>
                </div>

                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          id="c-name"
                          v-model="editInvestor.firstname"
                          class="form-control"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          id="c-email"
                          v-model="editInvestor.lastname"
                          class="form-control"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          id="c-occupation"
                          v-model="editInvestor.email"
                          class="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <select
                          class="form-control"
                          v-model="editInvestor.status"
                        >
                          <option value="active">Active</option>
                          <option value="disabled">Disabled</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Dismiss
            </button>

            <button
              id="btn-edit"
              @click.prevent="completeEditInvestor()"
              class="btn btn-success"
              :disabled="loadingEditInvestor"
            >
              <b-spinner small v-if="loadingEditInvestor"></b-spinner>
              <span v-else>Save Changes <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="modal fade" id="deleteInvestorModal"  role="dialog" aria-labelledby="deleteInvestorModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <i class="fa fa-close" data-dismiss="modal"></i>
                        <div class="add-contact-box">
                            <div class="add-contact-content">
                                <div class="text-center">
                                    <p class="text-danger">Are you sure you want to disable this Investor profile?</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn"  data-dismiss="modal"> Cancel</button>

                        <button @click.prevent="completeDeleteInvestor()" class="btn btn-danger text-white" :disabled="loadingDeleteInvestor">
                                <b-spinner small v-if="loadingDeleteInvestor"></b-spinner>
                            <span v-else>Confirm Disable <i class="fa fa-trash"></i></span> </button>
                    </div>
                </div>
            </div>
        </div>     -->
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { newInvestor, editInvestor } from "@/models/investor";
import $ from "jquery";
import _ from "lodash";

export default {
  name: "InvestorListComponent",

  data() {
    return {
      loading: true,
      investors: [],

      loadingNewInvestor: false,
      loadingEditInvestor: false,
      loadingDeleteInvestor: false,

      newInvestor: new newInvestor(),
      editInvestor: new editInvestor(),
      deleteInvestor: {
        id: "",
      },
    };
  },

  methods: {
    fetchInvestors() {
      this.loading = true;
      http
        .get(endpoints.FETCH_ALL_INVESTORS)
        .then((response) => {
          this.investors = response;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    addNewInvestor() {
      if (Object.values(this.newInvestor).some((x) => x == "")) {
        this.$toast.error("Missing input fields. Please try again!");
      } else {
        this.loadingNewInvestor = true;
        http
          .post(endpoints.ADD_NEW_INVESTOR, {
            ...this.newInvestor,
          })
          .then((response) => {
            this.$toast.success("Investor added succesfully!");
            this.newInvestor = _.mapValues(this.newInvestor, () => "");
            this.loadingNewInvestor = false;
            $("#addInvestorModal").modal("hide");
            this.investors.push(response);
          })
          .catch((error) => {
            this.loadingNewInvestor = false;
            this.$toast.error(error.response?.data?.message || error.response?.message);
          });
      }
    },

    startEditInvestorModal(investor) {
      this.editInvestor = investor;
      $("#editInvestorModal").modal("show");
    },

    completeEditInvestor() {
      if (Object.values(this.editInvestor).some((x) => x == "")) {
        this.$toast.error("Missing input fields. Please try again!");
      } else {
        this.loadingEditInvestor = true;
        http
          .put(endpoints.UPDATE_INVESTOR, {
            ...this.editInvestor,
          })
          .then((response) => {
            this.loadingEditInvestor = false;
            $("#editInvestorModal").modal("hide");
            this.$toast.success(response);
          })
          .catch((error) => {
            this.loadingEditInvestor = false;
            this.$toast.error(error.response?.data?.message || error.response?.message);
          });
      }
    },

    startDeleteInvestorModal(investor) {
      this.deleteInvestor.id = investor.id;
      $("#deleteInvestorModal").modal("show");
    },

    completeDeleteInvestor() {
      this.loadingDeleteInvestor = true;
      http
        .delete(
          endpoints.DELETE_INVESTOR.replace(":id", this.deleteInvestor.id)
        )
        .then((response) => {
          this.loadingDeleteInvestor = false;
          $("#deleteInvestorModal").modal("hide");
          this.$toast.success(response);
          this.fetchInvestors();
        })
        .catch((error) => {
          this.loadingDeleteInvestor = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },
  },
};
</script>
