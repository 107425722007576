<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="row layout-top-spacing">
        <div
          class="col-12 col-lg-2 text-center my-3 my-lg-0 order-1 order-lg-1"
        >
          <InvestorReportRecords />
        </div>

        <div
          class="col-12 col-md-12 col-lg-8 layout-spacing bg-white p-5 shadow order-3 order-lg-2"
        >
          <form>
            <div class="row mb-4">
              <div class="col-sm-6 col-12">
                <img
                  :src="`${url}/assets/img/gerocare-logo.png`"
                  alt=""
                  style="width: 60%; max-width: 300px"
                />
              </div>
              <div class="col-sm-6 col-12 align-self-center text-sm-right">
                <div class="company-info">
                  <span style="font-weight: bolder"
                    >Gerocare Solutions Limited</span
                  >
                  <br />
                  No. 7a Milverton Road, Ikoyi, Lagos.<br />
                  +234(0)7014448844 <br />
                  <a href="mailto:gerocare@gerocare.org"
                    >gerocare@gerocare.org</a
                  >
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="visitsold">Visits Sold</label>
                      <input
                        type="number"
                        v-model="visitsold"
                        id="visitsold"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="cash_in_bank">Cash In Bank</label>
                      <input
                        type="text"
                        v-model="cash_in_bank"
                        id="cash_in_bank"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="runway">Runway</label>
                      <input
                        type="text"
                        v-model="runway"
                        id="runway"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="text-right">
                  <button class="btn btn-success btn-xs">
                    Add New Month <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>

              <div class="col-12"></div>
            </div>
          </form>
        </div>

        <div
          class="col-12 col-lg-2 text-center my-3 my-lg-0 order-2 order-lg-3"
        >
          <InvestorList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InvestorList from "@/components/partials/InvestorList.vue";
import InvestorReportRecords from "@/components/partials/InvestorReportRecords.vue";

export default {
  name: "InvestorReports",
  components: {
    InvestorList,
    InvestorReportRecords,
  },

  mounted() {
    setTimeout(() => {}, 2000);
  },

  computed: {
    ...mapGetters(["url", "user"]),
  },

  data() {
    return {
      loading: false,
      visitsold: "",
      cash_in_bank: "",
      runway: "",
    };
  },

  methods: {},
};
</script>

<style scoped src="@/assets/css/apps/invoice.css"></style>
