export class newInvestor {
  constructor() {
    (this.firstname = ""), (this.lastname = ""), (this.email = "");
  }
}

export class editInvestor {
  constructor() {
    (this.id = ""),
      (this.firstname = ""),
      (this.lastname = ""),
      (this.email = "");
  }
}
